export type Special = {
  block: string;
  dateRequested: string;
  details: string;
  id: string;
  state: SpecialState;
};

export enum SpecialState {
  NEW = "New",
  ORDERED = "Ordered",
  CANCELLED = "Cancelled",
  COMPLETE = "Complete",
}
