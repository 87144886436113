import classNames from "classnames";
import React from "react";
import { Link } from "react-router";
import { CaseListTab } from "src/routes/CaseList";

import { PathologistCaseResult } from "../../schemas/ApiSchema";
import AmendmentRequiredTag from "./AmendmentRequiredTag";
import CaseCardInfo from "./CaseCardInfo";
import DateTag from "./DateTag";
import MicroReportLink from "./MicroReportLink";

export const TEST_ID_CASE_CARD = "CaseCard";
export const TEST_ID_CASE_CARD_TAGS = "CaseCardTags";

interface CaseCardProps {
  tab: CaseListTab;
  caseSummary: PathologistCaseResult;
}

const CaseCard = ({ tab, caseSummary }: CaseCardProps): React.JSX.Element => {
  const getCaseUrl = (labNumber: string, limsCaseId: string): string => {
    return "?id=" + btoa(`{"LabNumber":"${labNumber}","CaseID":"${limsCaseId}"}`);
  };
  const isUpcoming = tab === "Upcoming";
  const isAuthorised = tab === "Authorised";

  const {
    reportingPathologist,
    userGroupName,
    limsCaseId,
    labNumber,
    dateReceived,
    dateLastUpdated,
    amendmentRequired,
    secondOpinionPathologist,
  } = caseSummary;
  const caseUrl = getCaseUrl(labNumber, limsCaseId);

  const CaseCardContent = () => {
    return (
      <>
        <div className="columns is-mobile">
          <div className="column">
            <h4 className="title is-5">{labNumber}</h4>
          </div>
          <div className="tags" data-testid={labNumber + TEST_ID_CASE_CARD_TAGS}>
            {amendmentRequired && <AmendmentRequiredTag />}
            <DateTag
              action={isAuthorised ? "Updated" : "Received"}
              date={isAuthorised ? dateLastUpdated : dateReceived}
            />
          </div>
        </div>
        <div className="columns is-multiline">
          <div className="column is-4">
            <CaseCardInfo label={"User group"} value={userGroupName} />
          </div>
          <div className="column is-4">
            <CaseCardInfo label={"Reporting pathologist"} value={reportingPathologist} />
          </div>
          {secondOpinionPathologist && (
            <div className="column is-4">
              <CaseCardInfo
                label={"Second opinion pathologist"}
                value={secondOpinionPathologist}
              />
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <li
      key={labNumber}
      className={classNames("cy-case-card", {
        "cy-case-card--upcoming": isUpcoming,
      })}
      data-testid={labNumber + TEST_ID_CASE_CARD}
    >
      <div className="cy-case-card__content">
        {isUpcoming ? (
          <CaseCardContent />
        ) : (
          <Link to={caseUrl}>
            <CaseCardContent />
          </Link>
        )}
      </div>
      <div className={classNames({ "pb-5": isUpcoming })}>
        {!isUpcoming && <MicroReportLink caseUrl={caseUrl} />}
      </div>
    </li>
  );
};

export default CaseCard;
