import { Placeholder } from "@aws-amplify/ui-react";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sortBy } from "lodash";
import React, { useEffect, useState } from "react";

import { CustomError } from "../../schemas/ApiSchema";
import { dataService } from "../../services/data.service";

export const TEST_ID_SLIDE_LINKS_SECTION = "SlideLinksSection";
export const TEST_ID_SLIDE_LINKS_ERROR = "SlideLinksError";
export const TEST_ID_NO_SLIDES_MESSAGE = "NoSlidesMessage";
export const TEST_ID_SLIDE_LINKS_LIST = "SlideLinksList";

export interface ISlideLink {
  url: string;
  label: string;
}

interface SlideLinksProps {
  labNumber: string;
}

const SlideLinks = ({ labNumber }: SlideLinksProps): React.JSX.Element | null => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<CustomError>();
  const [slideLinks, setSlideLinks] = useState<ISlideLink[]>();

  useEffect(() => {
    const fetchSlides = async () => {
      const response = await dataService.getSlides(labNumber);
      if (response.data) {
        setSlideLinks(response.data);
      } else {
        setError(response.error);
      }
      setLoading(false);
    };
    fetchSlides();
  }, [labNumber]);

  const LoadingBars = (): React.JSX.Element => {
    return (
      <div style={{ marginTop: 21 }}>
        <Placeholder size="small" style={{ marginBottom: 18 }} />
        <Placeholder size="small" style={{ marginBottom: 18 }} />
        <Placeholder size="small" width="65%" />
      </div>
    );
  };

  const ErrorMessage = (): React.JSX.Element => {
    return (
      <p data-testid={TEST_ID_SLIDE_LINKS_ERROR}>
        Failed to load slides. Try reloading the page.
      </p>
    );
  };

  const NoSlidesFound = (): React.JSX.Element => {
    return (
      <p data-testid={TEST_ID_NO_SLIDES_MESSAGE}>No slides found for {labNumber}.</p>
    );
  };

  const SlideLink = ({ slide }: { slide: ISlideLink }): React.JSX.Element => {
    return (
      <li className="cy-data-list__item" key={slide.url}>
        <a href={slide.url} target="slide">
          <FontAwesomeIcon icon={faExternalLink} size="sm" className="mr-1" />{" "}
          {slide.label}
        </a>
      </li>
    );
  };

  return (
    <section data-testid={TEST_ID_SLIDE_LINKS_SECTION}>
      <h4 className="title is-6 mb-4">Slides</h4>
      {!error && loading && <LoadingBars />}
      {error && <ErrorMessage />}
      {slideLinks?.length === 0 && <NoSlidesFound />}
      {!!slideLinks?.length && (
        <ul data-testid={TEST_ID_SLIDE_LINKS_LIST}>
          {sortBy(slideLinks, ["label"]).map((slide, i) => (
            <SlideLink key={`slide-${i}`} slide={slide} />
          ))}
        </ul>
      )}
    </section>
  );
};

export default SlideLinks;
