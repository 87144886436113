import { faBan, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router";

import { TEST_ID_CASE_LIST_LINK } from "../components/dialogs/SaveReportDialog";

export const TEST_ID_CASE_NOT_EDITABLE = "CaseNotEditable";

interface CaseNotEditableProps {
  isAuthorised: boolean;
  isNonStandard: boolean;
  reportType: string;
}

const CaseNotEditable = ({
  isAuthorised,
  isNonStandard,
  reportType,
}: CaseNotEditableProps): React.JSX.Element => {
  function getHeading(): string {
    if (isAuthorised) {
      return `This ${reportType} has been authorised`;
    } else if (isNonStandard) {
      return `This case is not reportable in PathKit`;
    } else {
      // Assume any cases that are not authorised or non-standard are awaiting slides
      return `This case is not ready for reporting`;
    }
  }

  return (
    <section className="content" data-testid={TEST_ID_CASE_NOT_EDITABLE}>
      <h3 className="title is-4">
        <FontAwesomeIcon className="mr-2" icon={isAuthorised ? faCheck : faBan} />
        {getHeading()}
      </h3>
      {!isAuthorised && isNonStandard && (
        <p>
          This case has a non-standard report and therefore cannot be reported in PathKit.
        </p>
      )}
      <p>
        For {isAuthorised ? "subsequent amendments" : "further information"}, please
        contact our clinical and lab teams at{" "}
        <a href="mailto:support@cytedhealth.com">support@cytedhealth.com</a>.
      </p>
      <p>
        To report on another case, please{" "}
        <Link to="/" data-testid={TEST_ID_CASE_LIST_LINK}>
          return to the case list
        </Link>
        .
      </p>
    </section>
  );
};

export default CaseNotEditable;
