import React from "react";

import { IAnswer, IQuestion } from "../../types/builder";
import Choice from "./Choice";

interface CheckboxGroupProps {
  question: IQuestion;
  formShouldBeDisabled: boolean;
}

const CheckboxGroup = ({
  question,
  formShouldBeDisabled,
}: CheckboxGroupProps): React.JSX.Element => {
  const groupId = `${question.name}Options`;
  return (
    <div className="mb-4" data-testid={groupId}>
      {!!question.title && <h3 className="title is-6 mb-3">{question.title}</h3>}
      {question.options
        .filter((option: IAnswer) => !option.hidden)
        .map((option: IAnswer) => {
          return (
            <Choice
              type="checkbox"
              key={option.value}
              label={option.label}
              value={option.value}
              name={question.name}
              checked={question.selected?.includes(option.value) ?? false}
              disabled={option.disabled || formShouldBeDisabled}
              onChange={question.onChange}
            />
          );
        })}
    </div>
  );
};

export default CheckboxGroup;
