import { chain } from "lodash";

// For case data
export interface Pathologist {
  pathkitUserId: string;
  fullName: string;
  status: UserStatus;
  limsConsultantId: string | null;
}

export enum UserStatus {
  ENABLED = "Enabled",
  DISABLED = "Disabled",
}

// Pathologists must have a name, a LIMS consultant mapping, and enabled user status to be requested for second opinions
export const isValidPathologist = ({
  fullName,
  limsConsultantId,
  status,
}: Pathologist): boolean => {
  return (
    fullName.trim() !== "" && limsConsultantId !== null && status === UserStatus.ENABLED
  );
};

type FormSelectOption = { label: string; value: string };

export const mapPathologistsToListOptions = (
  pathologists: Pathologist[]
): FormSelectOption[] => {
  return (
    chain(pathologists)
      // Extract only the label and value properties required for a dropdown list
      .map(({ fullName, pathkitUserId }) => ({
        label: fullName,
        value: pathkitUserId,
      }))
      // Sort pathologists alphabetically by name
      .sortBy("label")
      .value()
  );
};

export const getActivePathologists = (
  pathologists: Pathologist[]
): FormSelectOption[] => {
  const activePathologists: Pathologist[] = pathologists.filter(isValidPathologist);
  return mapPathologistsToListOptions(activePathologists);
};
