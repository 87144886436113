import { faComments } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import * as yup from "yup";

import { requiredString } from "../../helpers/validators";
import { SecondOpinion } from "../../schemas/SecondOpinions";
import { dataService } from "../../services/data.service";
import CancelSecondOpinionButton from "./CancelSecondOpinionButton";

export const TEST_ID_SECOND_OPINIONS_LEGEND = "SecondOpinionsLegend";
export const TEST_ID_SECOND_OPINION_REQUEST_ASSIGNEE = "SecondOpinionRequestAssignee";
export const TEST_ID_READ_ONLY_SECOND_OPINION_RESPONSE = "ReadOnlySecondOpinionResponse";
export const TEST_ID_SECOND_OPINION_RESPONSE_TEXTAREA = "SecondOpinionResponseTextarea";
export const TEST_ID_READ_ONLY_SECOND_OPINION_REQUEST = "ReadOnlySecondOpinionRequest";
export const TEST_ID_SUBMIT_RESPONSE_BUTTON = "SubmitResponseButton";

interface SecondOpinionsProps {
  secondOpinions: SecondOpinion[];
  canEditMicro: boolean;
  currentUserId?: string;
  caseVersionId: string;
  toggleRefreshSecondOpinions: boolean;
  setToggleRefreshSecondOpinions: (x: boolean) => void;
}

interface SecondOpinionResponseProps {
  busy: boolean;
  secondOpinion: SecondOpinion;
  userIsSecondOpinionPathologist: boolean;
}

const formatNotesTimestamp = (date: string): string => {
  return `${format(date, "d MMM yyyy")} at ${format(date, "HH:mm")}`;
};

const SecondOpinionResponseSchema = yup
  .object({
    responseNotes: requiredString,
  })
  .required();

type SecondOpinionResponseForm = yup.InferType<typeof SecondOpinionResponseSchema>;

const SecondOpinionResponse = ({
  busy,
  secondOpinion: { dateResolved, assigneeName, responseNotes },
  userIsSecondOpinionPathologist,
}: SecondOpinionResponseProps) => {
  const { register } = useFormContext<SecondOpinionResponseForm>();

  return (
    <>
      <label className="label mb-3" htmlFor="reviewerComments">
        Response
        {dateResolved && (
          <p className="is-pulled-right is-size-7 has-text-weight-normal">
            {assigneeName} responded on {formatNotesTimestamp(dateResolved)}
          </p>
        )}
      </label>
      <div className="control" style={{ display: "block" }}>
        {responseNotes ? (
          <p className="mb-5" data-testid={TEST_ID_READ_ONLY_SECOND_OPINION_RESPONSE}>
            {responseNotes}
          </p>
        ) : (
          <textarea
            className="textarea mb-3"
            disabled={!userIsSecondOpinionPathologist || busy}
            placeholder="Not included in micro report"
            {...register("responseNotes")}
            data-testid={TEST_ID_SECOND_OPINION_RESPONSE_TEXTAREA}
          ></textarea>
        )}
      </div>
    </>
  );
};

const SecondOpinions = ({
  secondOpinions,
  currentUserId,
  canEditMicro,
  caseVersionId,
  toggleRefreshSecondOpinions,
  setToggleRefreshSecondOpinions,
}: SecondOpinionsProps): React.JSX.Element | null => {
  const { enqueueSnackbar } = useSnackbar();

  const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  // To begin with, we are only showing the most recent second opinion on a case
  const finalSecondOpinion = secondOpinions[secondOpinions.length - 1];

  const methods = useForm<SecondOpinionResponseForm>({
    mode: "onSubmit",
    defaultValues: {
      responseNotes: "",
    },
    resolver: yupResolver(SecondOpinionResponseSchema),
  });

  const onSubmit = async (form: SecondOpinionResponseForm): Promise<void> => {
    setError("");
    setBusy(true);
    const { responseNotes } = form;
    const response = await dataService.submitSecondOpinion(
      finalSecondOpinion.id,
      responseNotes,
      caseVersionId
    );
    if (response.data) {
      setBusy(false);
      setToggleRefreshSecondOpinions(!toggleRefreshSecondOpinions);
      enqueueSnackbar("Successfully submitted second opinion.", {
        variant: "success",
      });
    }
    if (response.error) {
      setBusy(false);
      setError(response.error.msg);
      enqueueSnackbar(
        "Failed to submit second opinion. Please refresh the page and try again. Your response will not be saved.",
        {
          variant: "error",
        }
      );
      console.log(error);
    }
  };

  const userIsSecondOpinionPathologist = currentUserId === finalSecondOpinion.assignee;

  return (
    <>
      <legend
        className="title is-4 mb-5 pt-5"
        style={{ borderTop: "1px dotted #c5ced3" }}
        data-testid={TEST_ID_SECOND_OPINIONS_LEGEND}
      >
        <span>
          <FontAwesomeIcon icon={faComments} className="mr-3" />
          Second opinions
        </span>
      </legend>
      {secondOpinions.map((secondOpinion) => {
        const {
          assigneeName,
          dateRequested,
          id,
          requestedByName,
          resolutionType,
          requestNotes,
        } = secondOpinion;
        return (
          <div key={id}>
            <div className="field mb-5">
              <label
                className="label is-flex is-align-items-baseline is-justify-content-space-between mb-3"
                htmlFor="requestNotes"
              >
                Request
                <p
                  className="is-pulled-right is-size-7 has-text-weight-normal"
                  data-testid={TEST_ID_SECOND_OPINION_REQUEST_ASSIGNEE}
                >
                  {userIsSecondOpinionPathologist
                    ? "Requested by " + requestedByName
                    : "Assigned to " + assigneeName}{" "}
                  on {formatNotesTimestamp(dateRequested)}
                </p>
              </label>
              <p data-testid={TEST_ID_READ_ONLY_SECOND_OPINION_REQUEST}>{requestNotes}</p>
            </div>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="field mb-3">
                  {(!canEditMicro || resolutionType === "Complete") && (
                    <SecondOpinionResponse
                      busy={busy}
                      secondOpinion={secondOpinion}
                      userIsSecondOpinionPathologist={userIsSecondOpinionPathologist}
                    />
                  )}
                </div>
                {userIsSecondOpinionPathologist && !resolutionType && (
                  <div className="field is-horizontal">
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <button
                            type="submit"
                            disabled={busy}
                            className={classNames("button is-primary mb-5", {
                              "is-loading": busy,
                            })}
                            data-testid={TEST_ID_SUBMIT_RESPONSE_BUTTON}
                          >
                            Send response
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {canEditMicro && !resolutionType && (
                  <CancelSecondOpinionButton
                    secondOpinionRequestId={id}
                    toggleRefreshSecondOpinions={toggleRefreshSecondOpinions}
                    setToggleRefreshSecondOpinions={setToggleRefreshSecondOpinions}
                  />
                )}
              </form>
            </FormProvider>
          </div>
        );
      })}
    </>
  );
};

export default SecondOpinions;
