import * as Sentry from "@sentry/browser";
import { debounce, isEmpty, merge } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router";

import AutosaveIndicator, { AutosaveStatus } from "../components/atoms/AutosaveIndicator";
import ReadyIndicator from "../components/atoms/ReadyIndicator";
import Accordion from "../components/common/Accordion";
import RequestSecondOpinionDialog from "../components/dialogs/RequestSecondOpinionDialog";
import RequestSpecialDialog from "../components/dialogs/RequestSpecialDialog";
import SaveReportDialog from "../components/dialogs/SaveReportDialog";
import TestModeDialog from "../components/dialogs/TestModeDialog";
import UserNotAuthorisedDialog from "../components/dialogs/UserNotAuthorisedDialog";
import LabWork from "../components/lab/LabWork";
import LinkToLims from "../components/navigation/LinkToLims";
import LoadingBackdrop from "../components/navigation/LoadingBackdrop";
import PageHeader from "../components/pages/PageHeader";
import PatientDetails from "../components/patient/PatientDetails";
import MicroQuestions, {
  TEST_ID_QUESTION_GROUPS,
} from "../components/questions/MicroQuestions";
import ReportButtons from "../components/questions/ReportButtons";
import PreviousReports from "../components/report/PreviousReports";
import ReportPreview from "../components/report/ReportPreview";
import SecondOpinions from "../components/second-opinions/SecondOpinions";
import { ONE_SECOND_MILLIS } from "../helpers/numbers";
import { SITE_TITLE } from "../helpers/strings";
import { isValidCaseId, isValidLabNumber } from "../helpers/validators";
import { GetCaseResult } from "../schemas/ApiSchema";
import { SecondOpinion } from "../schemas/SecondOpinions";
import { Special } from "../schemas/SpecialsSchema";
import { dataService } from "../services/data.service";
import { RootState } from "../store";
import { KnownBarretts, P53Stain } from "../types/answers";
import { IFormContents } from "../types/builder";
import {
  AllowedSlides,
  AuthorisedMicroReport,
  CaseState,
  CaseType,
  ICaseData,
} from "../types/case";
import CaseNotEditable from "../views/CaseNotEditable";
import ErrorPage from "./ErrorPage";

const ReportBuilder = (): React.JSX.Element => {
  // Redux
  const { currentUser } = useSelector((state: RootState) => state.auth);

  // Query string
  const [params] = useSearchParams();

  // Local state
  const [autosaveStatus, setAutosaveStatus] = useState<AutosaveStatus>(
    AutosaveStatus.SAVED
  );
  const [busy, setBusy] = useState<boolean>(true);
  const [caseId, setCaseId] = useState<string>("");
  const [data, setData] = useState<GetCaseResult>({
    internalCaseId: "",
    caseVersionId: undefined as string | undefined,
    caseData: {} as ICaseData,
    caseState: undefined as CaseState | undefined,
    caseType: null as CaseType | null,
    canEditMicro: true,
    canAuthoriseMicro: true,
    canRequestSpecial: true,
    micro: {
      reasonForAmendment: undefined,
      knownBarretts: undefined,
      adequateSample: undefined,
      // Older PathKit reports which didn't ask this question all had p53 stains,
      // so pre-select 'Yes' for those cases to ensure that the related questions
      // are visible. This is also the correct default for new reports.
      hasP53Stain: P53Stain.YES,
      inadequateSampleReason: undefined,
      pragueCircumferential: "",
      pragueMaximal: "",
      squamousAbnormality: undefined,
      squamousAtypia: undefined,
      squamousP53Staining: undefined,
      columnarGroups: undefined,
      tff3Staining: undefined,
      tff3PositiveGroupsSlideSectionOne: "",
      tff3PositiveGroupsSlideSectionTwo: "",
      columnarAtypia: undefined,
      columnarP53Staining: undefined,
      otherAbnormalities: [],
    } as IFormContents,
    isNonStandard: false,
    authorisedMicroReports: [] as AuthorisedMicroReport[],
    specials: [] as Special[],
  });
  const [secondOpinions, setSecondOpinions] = useState<SecondOpinion[]>();
  const [awaitingNewVersionId, setAwaitingNewVersionId] = useState<boolean>(false);
  const [caseNotFound, setCaseNotFound] = useState<boolean>(false);
  const [showSaveReportDialog, setShowSaveReportDialog] = useState<boolean>(false);
  const [showUserNotAuthorisedDialog, setShowUserNotAuthorisedDialog] =
    useState<boolean>(false);
  const [showRequestSpecialDialog, setShowRequestSpecialDialog] =
    useState<boolean>(false);
  const [showRequestSecondOpinionDialog, setShowRequestSecondOpinionDialog] =
    useState<boolean>(false);
  const [showTestModeDialog, setShowTestModeDialog] = useState<boolean>(false);
  const [toggleRefreshSecondOpinions, setToggleRefreshSecondOpinions] =
    useState<boolean>(false);
  const [isEveryRequiredQuestionAnswered, setIsEveryRequiredQuestionAnswered] =
    useState<boolean>(false);

  const {
    internalCaseId,
    caseVersionId: currentVersionId,
    caseData,
    caseState,
    caseType,
    canEditMicro,
    canAuthoriseMicro,
    canRequestSpecial,
    micro,
    isNonStandard,
    authorisedMicroReports: authorisedReports,
    specials,
  } = data;

  // PathKit runs in 'test mode' when lab number and/or case ID aren't present
  const testMode: boolean = !caseData?.labNumber || !caseId;

  // If the case has any previously-authorised reports then PathKit expects the
  // user to submit an amended report. PathKit does not simulate the amendment
  // flow in test mode.
  const isMicroAmendment = !testMode && canEditMicro && !isEmpty(authorisedReports);

  // Outbound links from the LIMS include a base64-encoded 'id' query
  // string parameter which includes a lab (CYT) number and case ID.
  const encodedIdParameter: string = params.get("id") ?? "";

  useEffect(() => {
    // Refresh the case before and after requesting specials and opening the save dialog
    // to ensure that the case permissions are up to date
    if (encodedIdParameter) {
      try {
        const decodedIdParameter: string = atob(encodedIdParameter);
        const { CaseID, LabNumber }: { CaseID: string; LabNumber: string } =
          JSON.parse(decodedIdParameter);
        if (isValidCaseId(CaseID) && isValidLabNumber(LabNumber)) {
          setCaseId(CaseID);
          setData((prev) => ({
            ...prev,
            caseData: prev.caseData && { ...prev.caseData, labNumber: LabNumber },
          }));
          fetchCase(LabNumber);
        } else {
          throw new RangeError(`Invalid CaseID '${CaseID}' or LabNumber '${LabNumber}'`);
        }
      } catch (error: unknown) {
        // We want to know who's used a link with a broken ID
        Sentry.captureException(error, { user: currentUser });
        setBusy(false);
        setCaseNotFound(true);
      }
    } else if (testMode) {
      setBusy(false);
      setShowTestModeDialog(true);
    } else {
      setBusy(false);
      setCaseNotFound(true);
    }
  }, [showRequestSpecialDialog, showSaveReportDialog]);

  useEffect(() => {
    if (!caseData || isEmpty(caseData?.labNumber)) return;
    else {
      const fetchSecondOpinions = async () => {
        const response = await dataService.getSecondOpinions(caseData.labNumber);
        if (response.data) {
          setSecondOpinions(response.data);
        }
      };
      fetchSecondOpinions();
    }
  }, [
    showRequestSecondOpinionDialog,
    caseData?.labNumber,
    toggleRefreshSecondOpinions,
    caseData,
  ]);

  useEffect(() => {
    // It's helpful to show the lab (CYT) number in the browser tab
    document.title = `${caseData?.labNumber ? `${caseData?.labNumber} | ` : ""}${SITE_TITLE}`;
  }, [caseData?.labNumber]);

  useEffect(() => {
    if (!canEditMicro) return;
    setAutosaveStatus(AutosaveStatus.PENDING);
    // Changes to any answers (micro) trigger an autosave request, *except* when
    // we're already waiting for a pending request to return a new versionId...
    if (!awaitingNewVersionId && autosaveStatus !== AutosaveStatus.SAVING) {
      debouncedSaveAnswers();
      return () => debouncedSaveAnswers.cancel();
    } else {
      // ...and in that case, set a flag to watch in the useEffect below which
      // will trigger an autosave request when we've received the new versionId.
      setAwaitingNewVersionId(true);
    }
  }, [micro]);

  useEffect(() => {
    // Triggered only when this *changes* from true to false, i.e. answers were
    // changed *during* an existing autosave request (see previous useEffect).
    if (!testMode && awaitingNewVersionId === false) {
      saveAnswers();
    }
  }, [awaitingNewVersionId]);

  const fetchCase = async (labNumber: string) => {
    const response = await dataService.getCase(labNumber);
    if (response.data) {
      // Merge any inferred and saved answers into local state
      const answers = mergeInferredAndSavedAnswers(response.data);
      setData((prev) => ({
        ...prev,
        ...(response.data as GetCaseResult),
        micro: answers, // Ensure `micro` uses the merged answers
      }));
    } else if (response.error.status === 401) {
      // User may not be in the necessary "pathologists" Cognito user group
      setShowUserNotAuthorisedDialog(true);
    } else {
      // Case doesn't exist in the LIMS (404), or it was already authorised (409)
      // or closed (400), so throw away the identifiers which were parsed
      // out of the 'id' query string parameter and show an error.
      setData((prev) => ({ ...prev, caseId: "", labNumber: "" }));
      setCaseNotFound(true);
    }
    setBusy(false);
  };

  const mergeInferredAndSavedAnswers = (response: GetCaseResult): IFormContents => {
    return merge(
      // 1. Start with empty/default answers in state
      micro,
      // 2. Try to infer answers from caseType and caseData (if available)
      inferBarrettsHistory(response.caseType),
      inferP53Staining(response.caseData?.slides),
      // 3. Finally give highest priority to any previously-saved answers
      response.micro
    );
  };

  const inferBarrettsHistory = (caseType: CaseType | null): IFormContents => {
    return {
      ...(caseType === CaseType.REFLUX && { knownBarretts: KnownBarretts.NO }),
      ...(caseType === CaseType.SURVEILLANCE && {
        knownBarretts: KnownBarretts.YES,
      }),
    };
  };

  const inferP53Staining = (slides?: AllowedSlides[]): IFormContents => {
    if (slides) {
      return {
        ...(slides.includes("P53")
          ? { hasP53Stain: P53Stain.YES }
          : { hasP53Stain: P53Stain.NO }),
      };
    }
    // Don't infer an answer if case data is missing or lacks slides
    return {};
  };

  // Used to save answers incrementally, but not more than once per second
  const debouncedSaveAnswers = debounce(
    async () => await saveAnswers(),
    ONE_SECOND_MILLIS
  );
  const saveAnswers = async (): Promise<void> => {
    if (!testMode && caseData && canEditMicro) {
      setAutosaveStatus(AutosaveStatus.SAVING);
      const response = await dataService.saveMicroReport(
        caseData?.labNumber,
        caseId,
        micro,
        "AUTOSAVE",
        currentVersionId
      );
      if (response.data) {
        setAutosaveStatus(AutosaveStatus.SAVED);
        setData((prev) => ({ ...prev, caseVersionId: response.data.versionId }));
        setAwaitingNewVersionId(false);
      } else if (response.error.status === 409 && window.confirm(response.error.msg)) {
        // Case was modified elsewhere since page load, so reload the page to
        // discard all unsaved answers and fetch the latest version
        location.reload();
      } else {
        // Other autosave errors should be transient, so only update the indicator
        setAutosaveStatus(AutosaveStatus.ERROR);
      }
    }
  };

  const setNotEditableOnAuthorisation = (
    authorisedReport: AuthorisedMicroReport
  ): void => {
    setData((prev) => ({
      ...prev,
      canEditMicro: false,
      canRequestSpecial: false,
      authorisedMicroReports: [authorisedReport, ...authorisedReports],
    }));
  };

  const updateMicro = (newMicro: IFormContents) => {
    setData((prev) => ({
      ...prev,
      micro: { ...newMicro },
    }));
  };

  const updateCurrentVersionId = (newVersionId: string) => {
    setData((prev) => ({
      ...prev,
      currentVersionId: newVersionId,
    }));
  };

  const caseIsOpen =
    caseState !== CaseState.LOCKED &&
    caseState !== CaseState.WITH_THE_LAB &&
    caseState !== CaseState.AWAITING_SLIDES;

  // Only allow 'Submit report' dialog to be opened when all questions have
  // been answered and autosaving has finished (or in test mode)
  const isSubmitReportButtonEnabled: boolean =
    canAuthoriseMicro &&
    isEveryRequiredQuestionAnswered &&
    [AutosaveStatus.SAVED, undefined].includes(autosaveStatus);

  if (busy) {
    return <LoadingBackdrop open title={`Loading ${caseData?.labNumber}`} />;
  }

  if (caseNotFound) {
    return (
      <ErrorPage
        subtitle="Case not found"
        message="The link you followed may be broken, or the case may have been authorised,
    closed, moved or deleted."
      />
    );
  }

  return (
    <>
      <div className="container is-max-widescreen">
        <PageHeader
          title={caseData?.labNumber ? `Case ${caseData?.labNumber}` : "PathKit"}
          subtitle={caseType ?? "Micro report builder"}
        />
        {!!caseId && <LinkToLims caseId={caseId} />}

        <div className="columns is-desktop is-align-items-flex-start">
          <div
            className="column is-two-fifths-desktop"
            data-testid={TEST_ID_QUESTION_GROUPS}
          >
            {isNonStandard || !caseIsOpen ? (
              <CaseNotEditable
                isAuthorised={
                  caseState === CaseState.LOCKED || caseState === CaseState.WITH_THE_LAB
                }
                isNonStandard={isNonStandard}
                reportType={authorisedReports.length > 1 ? "amendment" : "report"}
              />
            ) : (
              <MicroQuestions
                answers={micro}
                autosaveStatus={!testMode ? autosaveStatus : undefined}
                caseData={caseData}
                canAuthoriseMicro={canAuthoriseMicro}
                canEditMicro={canEditMicro}
                isMicroAmendment={isMicroAmendment}
                setAnswers={updateMicro}
                setIsEveryRequiredQuestionAnswered={setIsEveryRequiredQuestionAnswered}
              />
            )}
            {currentVersionId && secondOpinions && !isEmpty(secondOpinions) && (
              <SecondOpinions
                secondOpinions={secondOpinions}
                canEditMicro={canEditMicro}
                currentUserId={currentUser?.id?.toString()}
                caseVersionId={currentVersionId}
                toggleRefreshSecondOpinions={toggleRefreshSecondOpinions}
                setToggleRefreshSecondOpinions={setToggleRefreshSecondOpinions}
              />
            )}
          </div>
          <div
            className="column is-three-fifths-desktop"
            style={{ top: 0, position: "sticky" }}
          >
            {caseData && caseType && (
              <LabWork
                labNumber={caseData.labNumber}
                canRequestSpecial={canRequestSpecial}
                specials={specials}
                setShowRequestSpecialDialog={setShowRequestSpecialDialog}
                expanded={caseIsOpen && canRequestSpecial}
              />
            )}
            <PatientDetails
              caseData={caseData}
              caseType={caseType}
              expanded={caseIsOpen && canRequestSpecial}
            />
            {!isNonStandard && caseIsOpen && (
              <Accordion
                title={isMicroAmendment ? "Micro report amendment" : "Micro report"}
                id="microReport"
                expanded
                addon={
                  <div className="tags">
                    <ReadyIndicator isReady={isEveryRequiredQuestionAnswered} />
                    <AutosaveIndicator status={!testMode ? autosaveStatus : undefined} />
                  </div>
                }
              >
                <ReportPreview
                  answers={micro}
                  isMicroAmendment={isMicroAmendment}
                  previousReport={authorisedReports[0]}
                />
              </Accordion>
            )}
            <PreviousReports authorisedReports={authorisedReports} />
            {!testMode &&
              currentUser?.id?.toString() === caseData?.pathologistId &&
              caseIsOpen && (
                <ReportButtons
                  isSubmitReportButtonEnabled={isSubmitReportButtonEnabled}
                  setShowSaveReportDialog={setShowSaveReportDialog}
                  setShowRequestSecondOpinionDialog={setShowRequestSecondOpinionDialog}
                />
              )}
          </div>
        </div>
      </div>
      {showTestModeDialog && (
        <TestModeDialog setShowTestModeDialog={setShowTestModeDialog} />
      )}
      {showUserNotAuthorisedDialog && <UserNotAuthorisedDialog />}
      {showSaveReportDialog && caseData && (
        <SaveReportDialog
          labNumber={caseData?.labNumber}
          caseId={caseId}
          answers={micro}
          canAuthoriseMicro={canAuthoriseMicro}
          previousVersionId={currentVersionId}
          setNextVersionId={updateCurrentVersionId}
          setShowSaveReportDialog={setShowSaveReportDialog}
          setNotEditableOnAuthorisation={setNotEditableOnAuthorisation}
        />
      )}
      {showRequestSpecialDialog && (
        <RequestSpecialDialog
          internalCaseId={internalCaseId}
          setShowRequestSpecialDialog={setShowRequestSpecialDialog}
        />
      )}
      {showRequestSecondOpinionDialog && caseData && (
        <RequestSecondOpinionDialog
          internalCaseId={internalCaseId}
          reportingPathologist={caseData.pathologistId}
          canRequestSecondOpinion={isEmpty(
            !!secondOpinions && secondOpinions.filter((item) => !item.resolutionType)
          )}
          setShowRequestSecondOpinionDialog={setShowRequestSecondOpinionDialog}
        />
      )}
    </>
  );
};

export default ReportBuilder;
