import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { sortBy } from "lodash";
import React from "react";

import { Special, SpecialState } from "../../schemas/SpecialsSchema";

export const TEST_ID_SHOW_REQUEST_SPECIAL_BUTTON = "ShowRequestSpecialButton";
export const TEST_ID_SPECIAL_REQUEST = "SpecialRequest";
export const TEST_ID_NO_SPECIALS_REQUESTED = "NoSpecialsRequested";

interface SpecialsProps {
  canRequestSpecial: boolean;
  specials: Special[];
  setShowRequestSpecialDialog: (x: boolean) => void;
}

const Specials = ({
  canRequestSpecial,
  specials,
  setShowRequestSpecialDialog,
}: SpecialsProps): React.JSX.Element => {
  const sortedSpecials = sortBy(specials, "dateRequested");

  const RequestSpecialsButton = (): React.JSX.Element | null => {
    if (!canRequestSpecial) return null;
    return (
      <button
        className="button is-ghost is-size-7"
        onClick={() => setShowRequestSpecialDialog(true)}
        data-testid={TEST_ID_SHOW_REQUEST_SPECIAL_BUTTON}
      >
        <FontAwesomeIcon icon={faPlus} className="mr-1" />
        Request special
      </button>
    );
  };

  const SpecialsList = (): React.JSX.Element => {
    return (
      <table className="table">
        <tbody>
          {sortedSpecials.map(({ id, block, details, state }) => {
            const isPending =
              state === SpecialState.NEW || state === SpecialState.ORDERED;
            return (
              <tr key={id} data-testid={TEST_ID_SPECIAL_REQUEST + id}>
                <th
                  className={classNames(
                    "tag is-rounded is-light mr-2 mb-2 has-text-weight-normal",
                    {
                      "is-link": isPending,
                      "is-danger": state === SpecialState.CANCELLED,
                      "is-success has-text-success": state === SpecialState.COMPLETE,
                    }
                  )}
                  style={{ border: 0 }}
                >
                  {isPending ? "Pending" : state}
                </th>
                <td className="pt-0" style={{ border: 0 }}>
                  {block} — {details}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const NoSpecialsRequested = (): React.JSX.Element => {
    return <div data-testid={TEST_ID_NO_SPECIALS_REQUESTED}>No specials requested</div>;
  };

  return (
    <section>
      <div className="is-flex is-align-items-baseline">
        <h4 className="title is-6 mb-4">Specials</h4>
        {canRequestSpecial && <RequestSpecialsButton />}
      </div>
      {sortedSpecials.length > 0 ? <SpecialsList /> : <NoSpecialsRequested />}
      <p className="help mt-3">
        To cancel a special request, email our lab teams at{" "}
        <a href="mailto:support@cytedhealth.com">support@cytedhealth.com</a>.
      </p>
    </section>
  );
};

export default Specials;
