import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";

import { dataService } from "../../services/data.service";

export const TEST_ID_SECOND_OPINION_REQUEST_CANCEL_BUTTON =
  "SecondOpinionRequestCancelButton";

interface CancelSecondOpinionButtonProps {
  secondOpinionRequestId: string;
  toggleRefreshSecondOpinions: boolean;
  setToggleRefreshSecondOpinions: (x: boolean) => void;
}

const CancelSecondOpinionButton = ({
  secondOpinionRequestId,
  toggleRefreshSecondOpinions,
  setToggleRefreshSecondOpinions,
}: CancelSecondOpinionButtonProps): React.JSX.Element => {
  const [busy, setBusy] = useState<boolean>(false);

  const onClick = async () => {
    setBusy(true);
    const response = await dataService.cancelSecondOpinion(secondOpinionRequestId);
    setBusy(false);

    if (response.data) {
      enqueueSnackbar("Successfully cancelled second opinion request.", {
        variant: "success",
      });
      setToggleRefreshSecondOpinions(!toggleRefreshSecondOpinions);
    } else if (response.error) {
      enqueueSnackbar(
        "Failed to cancel second opinion request. Please reload the page and try again.",
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <button
      className="button is-primary"
      data-testid={TEST_ID_SECOND_OPINION_REQUEST_CANCEL_BUTTON}
      disabled={busy}
      onClick={onClick}
    >
      Cancel request
    </button>
  );
};

export default CancelSecondOpinionButton;
