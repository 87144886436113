import { IFormContents } from "../types/builder";
import { AuthorisedMicroReport, CaseState, CaseType, ICaseData } from "../types/case";
import { Pathologist } from "./PathologistSchema";
import { Special } from "./SpecialsSchema";

/**
 * This version number tracks changes to the API or JSON schemas,
 * e.g. those arising from changes to questions and/or answers in the UI.
 *
 * IF UPDATING THIS VALUE YOU MUST ALSO UPDATE THE VALUE IN LABKIT
 *
 * 1    First release
 * 1.1  #1014 Added hasP53Stain question (defaults to yes)
 * 2.0  Expose case information created by LabKit; change version field name.
 * 2.1  The previous case version ID is required when updating a case to prevent clashes.
 */
export const API_VERSION = "2.1";

export interface CustomError {
  error: string;
  msg: string;
  status?: number;
}

// API responses
export interface BootData {
  pathologists: Pathologist[];
}

export interface GetCaseResult {
  micro: IFormContents;
  isNonStandard: boolean;
  caseData?: ICaseData;
  caseState?: CaseState;
  caseType: CaseType | null;
  caseVersionId?: string;
  internalCaseId: string;
  canEditMicro: boolean;
  canAuthoriseMicro: boolean;
  canRequestSpecial: boolean;
  authorisedMicroReports: AuthorisedMicroReport[];
  specials: Special[];
}

export interface SaveMicroReportResult {
  submittedOk: boolean;
  versionId: string;
}

export type SubmissionType = "AUTOSAVE" | "DRAFT" | "AUTHORISED";

export interface GetPathologistCasesResponse {
  page: number;
  pageSize: number;
  totalPages: number;
  totalCases: number;
  status: string;
  cases: PathologistCaseResult[];
}

export type PathologistCaseResult = {
  reportingPathologist: string;
  userGroupName: string;
  limsCaseId: string;
  labNumber: string;
  dateReceived: string;
  dateLastUpdated: string;
  amendmentRequired: boolean;
  secondOpinionPathologist?: string;
};

// Filters out reports which were not issued by pathologist authorisation
// of the original micro report or any micro amendments, ie. reports which
// were reissued following a clerical correction and lock in LabKit.
export const originalAndAmendmentsOnly = (
  report: AuthorisedMicroReport,
  index: number,
  array: AuthorisedMicroReport[]
): boolean => {
  return (
    report.reasonForAmendment !== null || // Micro report amendments
    index === array.length - 1 // Always show the original report
  );
};
