import React from "react";
import { Special } from "src/schemas/SpecialsSchema";

import Accordion from "../common/Accordion";
import SlideLinks from "./SlideLinks";
import Specials from "./Specials";

interface LabWorkProps {
  labNumber: string;
  canRequestSpecial: boolean;
  specials: Special[];
  setShowRequestSpecialDialog: (x: boolean) => void;
  expanded?: boolean;
}
const LabWork = ({
  labNumber,
  canRequestSpecial,
  specials,
  setShowRequestSpecialDialog,
  expanded = false,
}: LabWorkProps): React.JSX.Element | null => {
  return (
    <Accordion expanded={expanded} title="Laboratory work" id="labWork">
      <div
        className="columns is-multiline is-variable is-5"
        style={{ fontSize: "0.875rem" }}
      >
        <div className="column is-half-tablet is-full-desktop is-half-widescreen">
          <SlideLinks labNumber={labNumber} />
        </div>
        <div className="column is-half-tablet is-full-desktop is-half-widescreen">
          <Specials
            canRequestSpecial={canRequestSpecial}
            specials={specials}
            setShowRequestSpecialDialog={setShowRequestSpecialDialog}
          />
        </div>
      </div>
    </Accordion>
  );
};

export default LabWork;
