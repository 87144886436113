import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, FormProvider, useForm, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router";
import Select from "react-select";
import * as yup from "yup";

import { requiredString } from "../../helpers/validators";
import { Pathologist, getActivePathologists } from "../../schemas/PathologistSchema";
import { dataService } from "../../services/data.service";
import { TEST_ID_RETURN_TO_CASES_LINK } from "./TestModeDialog";

export const TEST_ID_SELECT_SECOND_OPINION_PATHOLOGIST = "SelectSecondOpinionPathologist";
export const TEST_ID_SELECT_SECOND_OPINION_PATHOLOGIST_ERROR =
  "SelectSecondOpinionPathologistError";
export const TEST_ID_SELECT_SECOND_OPINION_PATHOLOGIST_INPUT =
  "SelectSecondOpinionPathologistInput";
export const TEST_ID_REQUEST_SECOND_OPINION_DIALOG = "RequestSecondOpinionDialog";
export const TEST_ID_SEC0ND_OPINION_REQUEST_DETAILS = "SecondOpinionRequestDetails";
export const TEST_ID_SEC0ND_OPINION_SUBMIT_BUTTON = "SecondOpinionSubmitButton";
export const TEST_ID_REQUEST_SECOND_OPINION_DIALOG_CANCEL_BUTTON =
  "RequestSecondOpinionDialogCancelButton";
export const TEST_ID_SEC0ND_OPINION_REQUEST_DETAILS_VALIDATION_ERROR =
  "SecondOpinionRequestDetailsValidationError";
export const TEST_ID_SELECT_SECOND_OPINION_PATHOLOGIST_VALIDATION_ERROR =
  "SelectSecondOpinionPathologistValidationError";
export const TEST_ID_CANNOT_REQUEST_SECOND_OPINION_WARNING_TEXT =
  "CannotRequestSecondOpinionWarningText";

interface RequestSecondOpinionDialogProps {
  internalCaseId: string;
  reportingPathologist: string;
  canRequestSecondOpinion: boolean;
  setShowRequestSecondOpinionDialog: (x: boolean) => void;
}

const SecondOpinionRequestSchema = yup
  .object({
    pathologistName: requiredString,

    // 'assignee: requiredString' was causing an exception (similar to https://github.com/primefaces/primeng/issues/12525) when running the
    // Cypress tests, so we've decided to remove the trim() for now.
    assignee: yup.string().required(),
    requestNotes: requiredString,
  })
  .required();

type SecondOpinionRequestForm = yup.InferType<typeof SecondOpinionRequestSchema>;

const SelectPathologist = ({
  reportingPathologist,
}: {
  reportingPathologist: string;
}): React.JSX.Element => {
  const [pathologists, setPathologists] = useState<Pathologist[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorLoadingPathologists, setErrorLoadingPathologists] =
    useState<boolean>(false);
  const { control, setValue, formState } = useFormContext<SecondOpinionRequestForm>();
  const hasAssigneeError = !!formState.errors.assignee;

  useEffect(() => {
    const fetchPathologists = async () => {
      setLoading(true);
      const response = await dataService.getBootData();
      if (response.data) {
        setPathologists(response.data.pathologists);
        setLoading(false);
      } else {
        setErrorLoadingPathologists(true);
      }
    };
    fetchPathologists();
  }, []);

  // Exclude inactive pathologists and the reporting pathologist
  const options = pathologists
    ? getActivePathologists(pathologists).filter(
        (item) => item.value !== reportingPathologist
      )
    : [];

  return (
    <div className="field mb-5">
      <label htmlFor="secondOpinionPathologist" className="label">
        Second opinion pathologist
      </label>
      <div className="control" data-testid={TEST_ID_SELECT_SECOND_OPINION_PATHOLOGIST}>
        {errorLoadingPathologists && (
          <p
            className="has-text-danger"
            data-testid={TEST_ID_SELECT_SECOND_OPINION_PATHOLOGIST_ERROR}
          >
            Error loading pathologists. Please close the dialog and try again.
          </p>
        )}
        <Controller
          name="assignee"
          control={control}
          defaultValue=""
          render={({ field }) => {
            return (
              <Select
                {...field}
                options={options}
                autoFocus={true}
                isMulti={false}
                value={options.find((option) => option.value === field.value)}
                placeholder={loading ? "Loading..." : "Type the pathologist's name"}
                menuPlacement="bottom"
                id={TEST_ID_SELECT_SECOND_OPINION_PATHOLOGIST_INPUT}
                classNamePrefix="react-select"
                className={classNames("react-select-container", {
                  "has-error": hasAssigneeError,
                })}
                styles={{
                  menuList: (base) => ({
                    ...base,
                    maxHeight: "200px",
                    overflowY: "auto",
                  }),
                }}
                onChange={(selectedOption) => {
                  field.onChange(selectedOption);
                  setValue("assignee", selectedOption?.value ?? "");
                  setValue("pathologistName", selectedOption?.label ?? "");
                }}
              />
            );
          }}
        />
      </div>
      <AssigneeValidationError />
    </div>
  );
};

const AssigneeValidationError = (): React.JSX.Element | null => {
  const { formState } = useFormContext<SecondOpinionRequestForm>();
  const hasAssigneeError = !!formState.errors.assignee;
  if (!hasAssigneeError) return null;

  return (
    <p
      className="mt-1 has-text-danger"
      data-testid={TEST_ID_SELECT_SECOND_OPINION_PATHOLOGIST_VALIDATION_ERROR}
    >
      Please select a pathologist
    </p>
  );
};

const RequestNotesTextArea = (): React.JSX.Element => {
  const { register, formState } = useFormContext<SecondOpinionRequestForm>();
  const hasRequestNotesError = !!formState.errors.requestNotes;

  return (
    <div className="field mb-0">
      <label className="label" htmlFor="requestNotes">
        Request notes
      </label>
      <div className="control">
        <textarea
          id="requestNotes"
          placeholder="Write your request here"
          className={classNames("textarea", { "is-danger": hasRequestNotesError })}
          data-testid={TEST_ID_SEC0ND_OPINION_REQUEST_DETAILS}
          {...register("requestNotes")}
        ></textarea>
      </div>
    </div>
  );
};

const RequestNotesValidationError = (): React.JSX.Element | null => {
  const { formState } = useFormContext<SecondOpinionRequestForm>();
  const hasRequestNotesError = !!formState.errors.requestNotes;
  if (!hasRequestNotesError) return null;

  return (
    <p
      className="mt-1 has-text-danger"
      data-testid={TEST_ID_SEC0ND_OPINION_REQUEST_DETAILS_VALIDATION_ERROR}
    >
      Please include details about your request
    </p>
  );
};

const RequestSecondOpinionDialog = ({
  internalCaseId,
  reportingPathologist,
  canRequestSecondOpinion,
  setShowRequestSecondOpinionDialog,
}: RequestSecondOpinionDialogProps): React.JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();

  const methods = useForm<SecondOpinionRequestForm>({
    mode: "onSubmit",
    defaultValues: {
      pathologistName: "",
      assignee: "",
      requestNotes: "",
    },
    resolver: yupResolver(SecondOpinionRequestSchema),
  });

  const onSubmit = async (form: SecondOpinionRequestForm): Promise<void> => {
    setError("");
    setBusy(true);
    const { assignee, requestNotes, pathologistName } = form;
    const response = await dataService.createSecondOpinion(
      internalCaseId,
      assignee,
      requestNotes
    );
    if (response.data) {
      enqueueSnackbar(
        `Successfully sent request for second opinion to ${pathologistName}.`,
        {
          variant: "success",
        }
      );
      handleClose();
    }
    if (response.error) {
      setBusy(false);
      setError(response.error.msg);
    }
  };

  const handleClose = () => !busy && setShowRequestSecondOpinionDialog(false);

  return (
    <div className="modal is-active" data-testid={TEST_ID_REQUEST_SECOND_OPINION_DIALOG}>
      <div className="modal-background" onClick={handleClose}></div>
      <div className="modal-content" style={{ maxWidth: 500 }}>
        <div className="box has-background-grey-lighter">
          <h3 className="title is-4">Request second opinion</h3>
          <div className="content">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                {canRequestSecondOpinion ? (
                  <>
                    <SelectPathologist reportingPathologist={reportingPathologist} />
                    <RequestNotesTextArea />
                    <RequestNotesValidationError />
                    <div className="buttons mt-5">
                      <button
                        type="submit"
                        className={classNames("button is-primary", {
                          "is-loading": busy,
                        })}
                        data-testid={TEST_ID_SEC0ND_OPINION_SUBMIT_BUTTON}
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        className="button is-light"
                        onClick={handleClose}
                        data-testid={TEST_ID_REQUEST_SECOND_OPINION_DIALOG_CANCEL_BUTTON}
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <p data-testid={TEST_ID_CANNOT_REQUEST_SECOND_OPINION_WARNING_TEXT}>
                      You cannot request a second opinion on this case until your existing
                      request has been resolved.
                    </p>
                    <div className="buttons mt-5">
                      <button
                        type="button"
                        className="button is-primary"
                        onClick={handleClose}
                        data-testid={TEST_ID_REQUEST_SECOND_OPINION_DIALOG_CANCEL_BUTTON}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="button is-light"
                        onClick={() => navigate("/")}
                        data-testid={TEST_ID_RETURN_TO_CASES_LINK}
                      >
                        Return to cases
                      </button>
                    </div>
                  </>
                )}
                {!!error && <p className="notification is-danger">{error}</p>}
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestSecondOpinionDialog;
