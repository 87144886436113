import React, { useEffect, useState } from "react";

import SortIndicator from "../components/atoms/SortIndicator";
import CaseCard from "../components/cases/CaseCard";
import CaseCount from "../components/cases/CaseCount";
import CaseListMenu from "../components/cases/CaseListMenu";
import LoadingSpinner from "../components/common/LoadingSpinner";
import PageHeader from "../components/pages/PageHeader";
import { SITE_TITLE } from "../helpers/strings";
import { PathologistCaseResult } from "../schemas/ApiSchema";
import { dataService } from "../services/data.service";

export const TEST_ID_CASE_LIST = "CaseList";

export type CaseListTab =
  | "ReadyForReporting"
  | "WithPendingRequests"
  | "Upcoming"
  | "Authorised"
  | "SecondOpinionRequests";

const CaseList = (): React.JSX.Element => {
  const [busy, setBusy] = useState<boolean>(true);
  const [cases, setCases] = useState<PathologistCaseResult[]>();
  const [totalCases, setTotalCases] = useState<Record<CaseListTab, number | undefined>>({
    ReadyForReporting: undefined,
    WithPendingRequests: undefined,
    Upcoming: undefined,
    Authorised: undefined,
    SecondOpinionRequests: undefined,
  });
  const [activeTab, setActiveTab] = useState<CaseListTab>("ReadyForReporting");

  const pageTitle = "Cases";

  useEffect(() => {
    const fetchCases = async (tab: CaseListTab) => {
      setBusy(true);
      const response = await dataService.getPathologistCases(tab);
      if (response.data) {
        setCases(response.data.cases);
        setTotalCases({
          ReadyForReporting:
            tab === "ReadyForReporting"
              ? response.data.totalCases
              : await fetchTotalCases("ReadyForReporting"),
          SecondOpinionRequests:
            tab === "SecondOpinionRequests"
              ? response.data.totalCases
              : await fetchTotalCases("SecondOpinionRequests"),
          Authorised: response.data.totalCases,
          WithPendingRequests:
            tab === "WithPendingRequests"
              ? response.data.totalCases
              : await fetchTotalCases("WithPendingRequests"),
          Upcoming:
            tab === "Upcoming"
              ? response.data.totalCases
              : await fetchTotalCases("Upcoming"),
        });
      } else {
        setTotalCases({
          ReadyForReporting: 0,
          WithPendingRequests: 0,
          Upcoming: 0,
          Authorised: 0,
          SecondOpinionRequests: 0,
        });
      }
      setBusy(false);
      return response.data;
    };

    fetchCases(activeTab);
    document.title = `${pageTitle} | ${SITE_TITLE}`;
  }, [activeTab]);

  const fetchTotalCases = async (tab: CaseListTab): Promise<number> => {
    const response = await dataService.getPathologistCases(tab);
    if (!response.data) {
      return 0;
    } else {
      return response.data?.totalCases;
    }
  };

  const getSubtitle = (tab: CaseListTab) => {
    switch (tab) {
      case "ReadyForReporting":
        return "Ready for reporting";
      case "SecondOpinionRequests":
        return "Second opinion requests";
      case "WithPendingRequests":
        return "Cases with pending requests";
      case "Upcoming":
        return "Upcoming cases";
      case "Authorised":
        return "Recently authorised";
    }
  };

  return (
    <div className="container is-max-widescreen">
      <PageHeader title="Cases" subtitle={getSubtitle(activeTab)} />
      <div className="columns is-variable is-4 is-justify-content-flex-start">
        <div className="column is-one-quarter">
          <CaseListMenu
            totalCases={totalCases}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
        <div className="column is-two-quarters pt-2">
          {busy && <LoadingSpinner />}
          {!busy && !!cases && cases.length > 0 && (
            <>
              <div className="is-flex is-justify-content-space-between is-align-items-baseline">
                <CaseCount tab={activeTab} caseCount={totalCases[activeTab] ?? 0} />
                <SortIndicator activeTab={activeTab} />
              </div>
              <ul className="mb-4" data-testid={TEST_ID_CASE_LIST + activeTab}>
                {cases.map((caseSummary) => (
                  <CaseCard
                    key={caseSummary.labNumber}
                    tab={activeTab}
                    caseSummary={caseSummary}
                  />
                ))}
              </ul>
            </>
          )}

          {!busy && (!cases || cases.length === 0) && (
            <h3 className="title is-size-4">No cases found</h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default CaseList;
