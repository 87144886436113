import { fetchAuthSession } from "aws-amplify/auth";
import axios, { AxiosInstance, AxiosResponse, isAxiosError } from "axios";
import { isNull, omitBy, pickBy } from "lodash";

import { ISlideLink } from "../components/lab/SlideLinks";
import {
  API_VERSION,
  BootData,
  CustomError,
  GetCaseResult,
  GetPathologistCasesResponse,
  SaveMicroReportResult,
  SubmissionType,
  originalAndAmendmentsOnly,
} from "../schemas/ApiSchema";
import { SecondOpinion } from "../schemas/SecondOpinions";
import { IFormContents, ManagementPriority } from "../types/builder";
import { CaseState } from "../types/case";

const getRestApi = (): AxiosInstance => {
  const restApi = axios.create({
    // Use Vite server proxy for /api requests in local dev
    baseURL: import.meta.env.VITE_APP_BACKEND_URL ?? "/api",
  });
  restApi.interceptors.request.use((config) => {
    // fetchAuthSession() refreshes tokens with Cognito if needed
    return fetchAuthSession()
      .then((session) => {
        config.headers.Authorization = `Bearer ${session.tokens?.idToken?.toString()}`;
        return Promise.resolve(config);
      })
      .catch(() => {
        return Promise.resolve(config);
      });
  });
  return restApi;
};

const getBootData = async () => {
  try {
    const response = await getRestApi().get("/pathkit/metadata");
    const data = parseResponse(response);
    return okResult(data as BootData);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const getCase = async (labNumber: string) => {
  try {
    const response = await getRestApi().get(`/case/${labNumber}`);
    const data = parseResponse(response);
    const {
      micro: { micro, isNonStandard },
      caseData,
      caseType,
      caseState,
      caseVersionId,
      internalCaseId,
      permissions: { canEditMicro, canAuthoriseMicro, canRequestSpecial },
      publishedReports,
      specialRequests,
    } = data;

    return okResult({
      micro: omitBy(micro, isNull),
      isNonStandard,
      // Ignore caseData which may be out of sync with the LIMS record
      caseData: caseState === CaseState.DELEGATED_TO_LIMS ? undefined : caseData,
      caseState,
      caseType,
      caseVersionId,
      internalCaseId,
      canEditMicro,
      canAuthoriseMicro,
      canRequestSpecial,
      authorisedMicroReports: publishedReports.filter(originalAndAmendmentsOnly),
      specials: specialRequests,
    } as GetCaseResult);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const getPathologistCases = async (status: string, page: number = 1) => {
  try {
    const response = await getRestApi().get(`/pathologist-cases`, {
      params: { page, status },
    });
    const data = parseResponse(response);
    return okResult(data as GetPathologistCasesResponse);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const getSlides = async (labNumber: string) => {
  try {
    const response = await getRestApi().get(`/slides/${labNumber}`);
    const data = parseResponse(response);
    const { slides } = data;
    return okResult(slides as ISlideLink[]);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const getSecondOpinions = async (labNumber: string) => {
  try {
    const response = await getRestApi().get(`/second-opinion/${labNumber}`);
    const data = parseResponse(response);
    const { secondOpinions } = data;
    return okResult(secondOpinions as SecondOpinion[]);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const createSpecial = async (caseId: string, block: string, requestDetails: string) => {
  try {
    const response = await getRestApi().post("/special", {
      caseId,
      block,
      requestDetails,
    });
    const data = parseResponse(response);
    return okResult(data.requestId as string);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const createSecondOpinion = async (
  caseId: string,
  assignee: string,
  requestNotes: string
) => {
  try {
    const response = await getRestApi().post("/second-opinion", {
      caseId,
      assignee,
      requestNotes,
    });
    const data = parseResponse(response);
    return okResult(data.requestId as string);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const submitSecondOpinion = async (
  requestId: string,
  responseNotes: string,
  caseVersionId: string
) => {
  try {
    const response = await getRestApi().put(`/second-opinion/${requestId}`, {
      responseNotes,
      caseVersionId,
    });

    const data = parseResponse(response);

    return okResult(data.ok as boolean);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const cancelSecondOpinion = async (requestId: string) => {
  try {
    const response = await getRestApi().delete(`/second-opinion/${requestId}`);
    const data = parseResponse(response);
    return okResult(data.ok as boolean);
  } catch (error: unknown) {
    console.log(error);
    return errorResult(error);
  }
};

const saveMicroReport = async (
  labNumber: string,
  caseId: string,
  answers: IFormContents,
  submissionType: SubmissionType,
  previousVersionId?: string,
  microHtml?: string,
  managementPriority?: ManagementPriority,
  snomedCodes?: string
) => {
  try {
    // Omit unanswered micro questions
    const micro = pickBy(answers, (answer) => !!answer?.length);
    const response = await getRestApi().put(`/micro/${labNumber}`, {
      apiVersion: API_VERSION,
      limsCaseId: caseId,
      micro,
      submissionType,
      previousCaseVersion: previousVersionId,
      microHtml,
      managementPriority,
      snomedCodes,
    });

    const data = parseResponse(response);

    return okResult(data as SaveMicroReportResult);
  } catch (error: unknown) {
    console.error(error);
    return errorResult(error);
  }
};

const parseResponse = (response: AxiosResponse) => {
  if (![200, 201].includes(response.status)) {
    throw Error(response.statusText);
  }
  if (!response.data) {
    return [];
  }
  let data = response.data;
  if (typeof data !== "object") {
    data = [];
  }
  return data;
};

export const okResult = <T>(data: T): { data: T; error: null } => ({
  data,
  error: null,
});

export const errorResult = (error: unknown): { data: null; error: CustomError } => ({
  data: null,
  error:
    isAxiosError(error) && error.response
      ? {
          // Try to get custom errors, fall back to Axios errors
          error: error.response.data.error ?? error.response.statusText,
          msg: error.response.data.msg ?? error.message,
          status: error.response.status ?? error.status,
        }
      : {
          error: "Error",
          msg: "",
          status: undefined,
        },
});

export const dataService = {
  getBootData,
  getCase,
  getPathologistCases,
  getSlides,
  getSecondOpinions,
  createSpecial,
  createSecondOpinion,
  submitSecondOpinion,
  cancelSecondOpinion,
  saveMicroReport,
};
