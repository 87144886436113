import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { ChangeEvent } from "react";

export const TEST_ID_NUMBER_PICKER_DECREMENT = "NumberPickerDecrement";
export const TEST_ID_NUMBER_PICKER_VALUE = "NumberPickerValue";
export const TEST_ID_NUMBER_PICKER_INCREMENT = "NumberPickerIncrement";

interface NumberPickerProps {
  id: string;
  min: number;
  max: number;
  value: string;
  disabled: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const NumberPicker = ({
  id,
  min,
  max,
  value,
  disabled,
  onChange,
}: NumberPickerProps): React.JSX.Element => {
  const currentValueAsNumber: number = parseInt(value, 10);

  // This component must emit ChangeEvent<HTMLInputElement> so we
  // have to update its input element in a way that fools React.
  const setNativeInput = (newValue: string): void => {
    const input = document.getElementById(id);
    if (input) {
      Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        "value"
      )?.set?.call(input, newValue);
      input.dispatchEvent(new Event("change", { bubbles: true }));
    }
  };

  const handleStep = (step: number): void => {
    if (!isNaN(currentValueAsNumber)) {
      const newValue: number = currentValueAsNumber + step;
      if (newValue >= min && newValue <= max) {
        setNativeInput(newValue.toString());
      }
    } else {
      setNativeInput(min.toString());
    }
  };

  return (
    <div className="cy-number-picker">
      <div className="field has-addons">
        <div className="control">
          <button
            disabled={disabled}
            className="cy-number-picker__button"
            aria-label="Decrement"
            onClick={() => handleStep(-1)}
            data-testid={TEST_ID_NUMBER_PICKER_DECREMENT}
          >
            <FontAwesomeIcon icon={faMinus} />
          </button>
        </div>
        <div className="control">
          <input
            readOnly
            type="text"
            id={id}
            name={id}
            data-testid={`${id}Number`}
            value={value}
            placeholder="?"
            className={classNames({
              "cy-number-picker__input": true,
              "hide-value": isNaN(currentValueAsNumber),
            })}
            onChange={onChange}
          />
        </div>
        <div className="control">
          <button
            disabled={disabled}
            className="cy-number-picker__button"
            aria-label="Increment"
            onClick={() => handleStep(1)}
            data-testid={TEST_ID_NUMBER_PICKER_INCREMENT}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default NumberPicker;
