import { faPlus, faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const TEST_ID_SUBMIT_REPORT_BUTTON = "SubmitReportButton";
export const TEST_ID_REQUEST_SECOND_OPINION_BUTTON = "RequestSecondOpinionButton";

interface ReportButtonsProps {
  isSubmitReportButtonEnabled: boolean;
  setShowSaveReportDialog: (X: boolean) => void;
  setShowRequestSecondOpinionDialog: (X: boolean) => void;
}

const ReportButtons = ({
  isSubmitReportButtonEnabled,
  setShowSaveReportDialog,
  setShowRequestSecondOpinionDialog,
}: ReportButtonsProps): React.JSX.Element => {
  return (
    <div className="columns">
      <div className="column pr-1 pt-0">
        <button
          className="button is-primary is-fullwidth is-large"
          disabled={!isSubmitReportButtonEnabled}
          data-testid={TEST_ID_SUBMIT_REPORT_BUTTON}
          onClick={() => setShowSaveReportDialog(true)}
        >
          <FontAwesomeIcon icon={faUpRightFromSquare} className="mr-3" />
          Submit report
        </button>
      </div>
      <div className="column pl-1 pt-0">
        <button
          className="button is-secondary is-fullwidth is-large"
          onClick={() => setShowRequestSecondOpinionDialog(true)}
          data-testid={TEST_ID_REQUEST_SECOND_OPINION_BUTTON}
        >
          <FontAwesomeIcon icon={faPlus} className="mr-3" />
          Request second opinion
        </button>
      </div>
    </div>
  );
};

export default ReportButtons;
